import * as React from "react";
import Layout from "../components/layout";
import ScheduleOfFeesContent from "../components/enrolment/scheduleoffeesContent";
import heroImage from "../assets/enrolment/enrolment-hero-image.jpg";
import BannerImage from "../components/master/BannerImage";

const ScheduleOfFeesPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="..." />
      <ScheduleOfFeesContent />
    </Layout>
  );
};

export default ScheduleOfFeesPage;
