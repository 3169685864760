import React from "react";
import enrolmentBottomImage from "../../assets/enrolment/bottomEnrolment.jpg";
import SideBar from "../master/SideBar";
import ContentWrapper from "../master/ContentWrapper";
import {EnrolmentSidebarLink} from "../const";
import PageTitle from "../master/PageTittle";

const ScheduleOfFeesContent = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="School Fees"/>

                <PageTitle title="Enrolment Fee" variant="h3"/>

                <p>
                    A non-refundable enrolment fee of $250 payable at the time of
                    submitting an Application for Enrolment to the College.
                </p>
                <p>
                    Fee for students coming from a Maronite Sisters of the Holy Family
                    Pre-School is $150.
                </p>

                <PageTitle title="College Fees" variant="h3"/>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Year Group</th>
                        <th scope="col">Tuition</th>
                        <th scope="col">Resource Fees</th>
                        <th scope="col">Annual</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">K - Year 6</th>
                        <td>$2,265</td>
                        <td>$945</td>
                        <td>$3,210</td>
                    </tr>
                    <tr>
                        <th scope="row">Year 7 - 8</th>
                        <td>$3,285</td>
                        <td>$1,134</td>
                        <td>$4,419</td>
                    </tr>
                    <tr>
                        <th scope="row">Year 9 - 10</th>
                        <td>3,666</td>
                        <td>$1,134</td>
                        <td>$4,800</td>
                    </tr>
                    <tr>
                        <th scope="row">Year 11 - 12</th>
                        <td>$4,434</td>
                        <td>$843</td>
                        <td>$5,277</td>
                    </tr>
                    </tbody>
                </table>


                <PageTitle title="Building Levy" variant="h3"/>
                <p>
                    Each family is required to pay a building levy of $450 this is used to
                    help pay for the maintenance and improvement of college buildings.
                </p>

                <PageTitle title="Resource Fees" variant="h3"/>
                <p>
                    This fee provides for student learning resources and includes copyright licences, library resources,
                    sport events including the swimming and sports carnivals and IT requirements to support student
                    learning
                </p>


                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Year Group</th>
                        <th scope="col">Resource</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope={"row"}>Resource Fees</th>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">K - Year 6</th>
                        <td>$900</td>

                    </tr>
                    <tr>
                        <th scope="row">Year 7 - 8</th>
                        <td>$1,080</td>

                    </tr>
                    <tr>
                        <th scope="row">Year 9 - 10</th>
                        <td>$1,080</td>

                    </tr>
                    <tr>
                        <th scope="row">Year 11 - 12</th>
                        <td>$801</td>

                    </tr>
                    </tbody>
                </table>

                <PageTitle title="DISCOUNTS" variant="h3"/>
                <p>
                    Sibling discounts are given on tuition fees only, at the following
                    rates:
                </p>
                <p>2nd child - 10%</p>
                <p>3rd child - 40%</p>
                <p>4th child and more 100%</p>

                <PageTitle title="Additional Fees" variant="h3"/>
                <p>
                    Excursions, camps and swimming programs may be organised through the
                    year, which will be in addition to the above fees.
                </p>
                <p>
                    Also students participating in the following courses will incur
                    additional fees to meet the cost of materials.
                </p>
                <p>TAFE (TVET) - $850</p>
                <p>Hospitality (TVET) - $300</p>
                <p>Food Technology (Yrs 9 - 10) - $100</p>
                <p>Woodwork (Yrs 9 - 10) - $100</p>
                <p>STEM (Yrs 9 - 10) - $90</p>

                <PageTitle title="Excursion Levy" variant="h3"/>
                <p>
                    The Excursion Levy covers the cost of swimming programs, carnivals, sport events,
                    excursions, camps and incursions that have been planned for each year group.
                </p>
                <p>
                    Primary
                </p>

                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">K</th>
                        <th scope="col">1</th>
                        <th scope="col">2</th>
                        <th scope="col">3</th>
                        <th scope="col">4</th>
                        <th scope="col">5</th>
                        <th scope="col">6</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Excursions,
                            Camp &
                            Incursions</th>
                        <td>$130.00</td>
                        <td>$130.00</td>
                        <td>$80.00</td>
                        <td>$180.00</td>
                        <td>$180.00</td>
                        <td>$180.00</td>
                        <td>$400.00</td>
                    </tr>
                    <tr>
                        <th scope="row">Gala and
                            Interschool
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>$65.00</td>
                        <td>$65.00</td>
                        <td>$135.00</td>
                        <td>$135.00</td>
                    </tr>
                    <tr>
                        <th scope="row">Carnivals –
                            Swimming and
                            Athletics
                        </th>
                        <td>$220.00</td>
                        <td>$220.00</td>
                        <td>$220.00</td>
                        <td>$220.00</td>
                        <td>$220.00</td>
                        <td>$220.00</td>
                        <td>$220.00</td>
                    </tr>
                    <tr>
                        <th scope="row">TOTAL
                        </th>
                        <td>$350.00</td>
                        <td>$350.00</td>
                        <td>$300.00</td>
                        <td>$465.00</td>
                        <td>$465.00</td>
                        <td>$535.00</td>
                        <td>$755.00</td>
                    </tr>
                    </tbody>
                </table>

                <p>
                    Secondary
                </p>

                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">7</th>
                        <th scope="col">8</th>
                        <th scope="col">9</th>
                        <th scope="col">10</th>
                        <th scope="col">11</th>
                        <th scope="col">12</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Carnivals –
                            Swimming and
                            Athletics</th>
                        <td>$170.00</td>
                        <td>$170.00</td>
                        <td>$170.00</td>
                        <td>$20.00</td>
                        <td>$30.00</td>
                        <td>$30.00</td>
                    </tr>
                    <tr>
                        <th scope="row">Excursions,
                            Camp &
                            Incursions</th>
                        <td>$85.00</td>
                        <td>$85.00</td>
                        <td>$85.00</td>
                        <td>$85.00</td>
                        <td>$420.00</td>
                        <td>$470.00</td>
                    </tr>


                    <tr>
                        <th scope="row">TOTAL
                        </th>
                        <td>$255.00</td>
                        <td>$255.00</td>
                        <td>$255.00</td>
                        <td>$105.00</td>
                        <td>$450.00</td>
                        <td>$500.00</td>

                    </tr>
                    </tbody>
                </table>

                <PageTitle title="After School Care" variant="h3"/>
                <p>
                    St Maroun’s College offers After School Care for Primary Students. The cost is $35.00
                    per child, per session.
                </p>

                <PageTitle title="Accounts" variant="h3"/>
                <p>
                    Accounts are billed three times a year, at the beginning of term 1, 2 and 3.
                    Payments can be made weekly, fortnightly, monthly, per term or annually.
                </p>
                <p>
                    College fees can be paid using BPAY, Cheque, Credit Card or by Standing Authority (Credit Card).
                </p>


                <PageTitle title="Withdrawal Of Enrolment" variant="h3"/>
                <p>
                    A term’s notice (10 school weeks) in writing must be given to the principal before the removal of a
                    student or a full terms fee will be payable. Exception is that notice in writing will be accepted at
                    any time during Term 4 in relation to the following year’s enrolment, that is, the notice period of
                    10 school weeks will be waived in this circumstance.
                </p>

            </div>

            <SideBar items={EnrolmentSidebarLink} title="Enrolments"/>
        </ContentWrapper>

        <img
            src={enrolmentBottomImage}
            className="img-fluid justify-content-center "
            height={600}
            alt="..."
        />
    </div>
);

export default ScheduleOfFeesContent;
